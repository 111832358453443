import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const EditTemplatePage = ({ token }) => {
  const [mailTemplate, setMailTemplate] = useState("");
  const [mailSubject, setMailSubject] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_BACKEND_URL + "/api/user/me",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch template");
        }

        const data = await response.json();
        const { mailTemplate, mailSubject } = data;
        setMailTemplate(mailTemplate);
        setMailSubject(mailSubject);
      } catch (error) {
        console.error("Error fetching template:", error);
      }
    };

    fetchTemplate();
  }, [token]);

  const renderHighlightedTemplate = (template) => {
    const variableRegex = /{{\s*[A-Za-z_]+\s*}}|{{[A-Za-z_]+}}/g;
    const parts = template.split(variableRegex);
    const variableMatches = template.match(variableRegex) || [];

    return parts.map((part, index) => (
      <React.Fragment key={index}>
        {part}
        {variableMatches[index] && (
          <span className="font-bold text-red-500">
            {variableMatches[index]}
          </span>
        )}
      </React.Fragment>
    ));
  };

  // Function to check for incorrect variable formats
  const checkVariableFormats = () => {
    const incorrectFormat =
      /{([A-Za-z_]+)}|{{\s*[A-Za-z_]+\s*}}|{{[A-Za-z_]+}}/g; // Regex for incorrect formats
    return incorrectFormat.test(mailTemplate);
  };

  const handleUpdate = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/api/user/update-template",
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ mailTemplate, mailSubject }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update template");
      }

      alert("Template updated successfully!");
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating template:", error);
    }
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white shadow-md rounded-md">
      <h1 className="text-xl font-bold mb-4">Email Template</h1>
      {isEditing && checkVariableFormats() && (
        <p className="text-red-500 mb-4">
          {`Variables must be in the format {{ variable }} or {{variable}}.`}
        </p>
      )}
      {isEditing ? (
        <>
          <input
            type="text"
            value={mailSubject}
            onChange={(e) => setMailSubject(e.target.value)}
            className="w-full p-2 border mb-4 rounded-md"
            placeholder="Mail Subject"
          />
          <textarea
            value={mailTemplate}
            onChange={(e) => setMailTemplate(e.target.value)}
            className="w-full p-2 border rounded-md"
            rows="8"
          />
          <button
            onClick={handleUpdate}
            className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-md"
          >
            Save
          </button>
        </>
      ) : (
        <>
          <h2 className="text-lg font-semibold mb-2">{mailSubject}</h2>
          <pre className="whitespace-pre-wrap">
            {renderHighlightedTemplate(mailTemplate)}
          </pre>
          <button
            onClick={() => setIsEditing(true)}
            className="mt-4 bg-green-500 text-white py-2 px-4 rounded-md"
          >
            Edit Template
          </button>
        </>
      )}
    </div>
  );
};

export default EditTemplatePage;
