import React, { useEffect, useState, useRef, useCallback } from "react";

const CardsPage = ({ token }) => {
  const [cards, setCards] = useState([]);
  const [limit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [selectedCard, setSelectedCard] = useState(null);
  const loaderRef = useRef(null);
  const modalRef = useRef(null);

  const handleObserver = useCallback(
    (entries) => {
      const fetchCards = async () => {
        try {
          const response = await fetch(
            process.env.REACT_APP_BACKEND_URL + "/api/card/list",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({ limit, offset }),
            }
          );

          if (!response.ok) {
            throw new Error("Failed to fetch cards");
          }

          const data = await response.json();
          const { cards: newCards, count } = data;

          setCards((prevCards) => [...prevCards, ...newCards]);
          setOffset((prevOffset) => prevOffset + limit);
          setHasMore(cards.length + newCards.length < count);
        } catch (error) {
          console.error("Error fetching cards:", error);
        }
      };
      const target = entries[0];
      if (target.isIntersecting && hasMore) {
        fetchCards();
      }
    },
    [hasMore, limit, offset, token, cards.length]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    });

    if (loaderRef.current) observer.observe(loaderRef.current);

    return () => observer.disconnect();
  }, [handleObserver]);

  const closeModal = () => setSelectedCard(null);

  const handleClickOutsideModal = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    if (selectedCard) {
      document.addEventListener("mousedown", handleClickOutsideModal);
    } else {
      document.removeEventListener("mousedown", handleClickOutsideModal);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideModal);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCard]);

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-4">Visiting Cards</h2>
      <div
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 overflow-y-auto"
        style={{ maxHeight: "80vh", maxWidth: "100vw" }}
      >
        {cards.map((card) => (
          <div
            key={card.id}
            className="bg-white shadow-md p-4 rounded-lg hover:shadow-lg transition cursor-pointer"
            onClick={() => setSelectedCard(card)}
          >
            <h3 className="text-lg font-semibold">
              {card.firstName} {card.lastName}
            </h3>
            <p className="text-gray-600">{card.position}</p>
            <p className="text-gray-500">{card.company}</p>
            <p className="text-blue-500">{card.email}</p>
            <p className="text-gray-500">{card.mobile || "-"}</p>
            <a
              href={card.website}
              target="_blank"
              rel="noreferrer"
              className="text-blue-600 hover:underline"
            >
              {card.website}
            </a>
          </div>
        ))}
      </div>

      {hasMore && (
        <div ref={loaderRef} className="text-center py-6">
          <p>Loading more cards...</p>
        </div>
      )}

      {selectedCard && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div
            ref={modalRef}
            className="bg-white p-6 rounded-lg shadow-lg relative max-w-lg"
          >
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              &times;
            </button>
            <img
              src={selectedCard.imageUrl}
              alt={`${selectedCard.firstName} ${selectedCard.lastName}`}
              className="w-full max-h-96 rounded-md mb-3 object-cover"
            />
            <h3 className="text-lg font-semibold">
              {selectedCard.firstName} {selectedCard.lastName}
            </h3>
            <p className="text-gray-600">{selectedCard.position}</p>
            <p className="text-gray-500">{selectedCard.company}</p>
            <p className="text-blue-500">{selectedCard.email}</p>
            <p className="text-gray-500">{selectedCard.mobile}</p>
            <p className="text-gray-500">
              <strong>Back Image:</strong>
              {selectedCard.backImageUrl ? (
                <a
                  href={selectedCard.backImageUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-600 hover:underline"
                >
                  back image
                </a>
              ) : (
                <span>No back image available</span>
              )}
            </p>
            <a
              href={selectedCard.website}
              target="_blank"
              rel="noreferrer"
              className="text-blue-600 hover:underline"
            >
              {selectedCard.website}
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default CardsPage;
