import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import LoginPage from "./page/LoginPage";
import PhotoUploadPage from "./page/PhotoUploadPage";
import EditTemplatePage from "./page/EditTemplatePage";
import CardsPage from "./page/CardsPage";

const NavBar = () => {
  const location = useLocation();

  // Function to check if a route is active
  const isActive = (path) => location.pathname === path;

  return (
    <nav className="w-full bg-black text-white py-4">
      <div className="max-w-6xl mx-auto px-4 flex justify-between items-center">
        <h1 className="text-xl font-bold">Connect</h1>
        <div>
          <Link
            to="/upload"
            className={`mr-4 hover:underline ${
              isActive("/upload") ? "font-bold underline" : ""
            }`}
          >
            Upload
          </Link>
          <Link
            to="/edit-template"
            className={`hover:underline ${
              isActive("/edit-template") ? "font-bold underline" : ""
            }`}
          >
            Template
          </Link>
          <Link
            to="/cards"
            className={`ml-4 hover:underline ${
              isActive("/cards") ? "font-bold underline" : ""
            }`}
          >
            Cards
          </Link>
        </div>
      </div>
    </nav>
  );
};

const App = () => {
  const [token, setToken] = useState(null);
  const location = useLocation();

  const showNavBar = location.pathname !== "/";

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      {showNavBar && <NavBar />}
      <div className="flex-grow flex items-center justify-center">
        <Routes>
          <Route path="/" element={<LoginPage setToken={setToken} />} />
          <Route path="/upload" element={<PhotoUploadPage token={token} />} />
          <Route path="/cards" element={<CardsPage token={token} />} />
          <Route
            path="/edit-template"
            element={<EditTemplatePage token={token} />}
          />
        </Routes>
      </div>
    </div>
  );
};

const RootApp = () => (
  <Router>
    <App />
  </Router>
);

export default RootApp;
