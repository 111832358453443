import React, { useState, useRef } from "react";
import PinInput from "react-pin-input";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

const LoginPage = ({ setToken }) => {
  const [loginLoading, setLoginLoading] = useState(false);
  const pinRef = useRef(null);
  const navigate = useNavigate();

  // Handle login with PIN
  const handlePinComplete = async (value) => {
    setLoginLoading(true);
    try {
      const res = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/api/auth/login",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ pin: value }),
        }
      );

      const data = await res.json();

      if (res.ok) {
        setToken(data.token);
        navigate("/upload");
      } else {
        alert("Incorrect PIN");
        pinRef.current.clear();
        setTimeout(() => pinRef.current.focus(), 1);
      }
    } catch (error) {
      console.error("Error during login:", error);
      alert("Login failed, please try again.");
      pinRef.current.clear();
      setTimeout(() => pinRef.current.focus(), 1);
    } finally {
      setLoginLoading(false);
    }
  };

  return (
    <motion.div
      className="flex flex-col items-center"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h1 className="text-3xl font-bold mb-6">Enter 4-digit PIN</h1>
      <PinInput
        length={4}
        focus
        ref={pinRef}
        type="numeric"
        inputMode="numeric"
        inputStyle={{
          borderColor: "gray",
          borderWidth: "2px",
          margin: "0.5rem",
          padding: "1rem",
          borderRadius: "8px",
        }}
        onComplete={(value) => handlePinComplete(value)}
        disabled={loginLoading}
      />

      {loginLoading && (
        <div className="mt-4 flex items-center justify-center">
          <ClipLoader size={30} color="#3b82f6" />
        </div>
      )}
    </motion.div>
  );
};

export default LoginPage;
